import styled from 'styled-components';
import { COLOR } from '../../constants';
import { mobileMixin } from '../../styles';

interface IVideoContentContainerProps {
  reversed?: boolean;
}

export const VideoContentContainer = styled.div<IVideoContentContainerProps>`
  width: 100%;
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-direction: ${props => (props.reversed ? 'row-reverse' : 'row')};

  ${mobileMixin(`
    flex-direction: column;
  `)}
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: max-content;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Link = styled.a`
  color: ${COLOR.DARKBLUE};
  font-size: 18px;
`;
