import React from 'react';

import { graphql, Node } from 'gatsby';

import useIsDesktop from '../../lib/isDesktop';
import { Layout, Container, InsightListings } from '../../components';
import { PageHeader, HalfContentSubheader, ContentHeader, ContentParagraph, PaddingLarge } from '../../styles';
import content from './content';
import { COLOR } from '../../constants';
import { IArticleGroup } from 'queries/articles';
import Fade from 'react-reveal/Fade';
import VideoContent from '../../components/VideoContent';

const InsightsPage = ({ data, path }: any) => {
  const isDesktop = useIsDesktop();

  const convertMarkdownData = (edges: any): IArticleGroup[] => {
    const listings: IArticleGroup[] = [];

    edges.forEach((edge: any) => {
      listings.push(edge.node);
    });

    return listings;
  };

  const webinars = content.webinarSection.webinars.map((webinar, index) => {
    return (
      <VideoContent
        reversed={index % 2 !== 0}
        description={webinar.description}
        title={webinar.title}
        subheader={webinar.subheader}
        linkText={webinar.linkText}
        linkUrl={webinar.linkUrl}
        videoUrl={webinar.videoUrl}
      />
    );
  });

  return (
    <Layout isDesktop={isDesktop} path={path} title={content.title}>
      <Fade>
        <Container flexDirection={'column'}>
          <PageHeader color={COLOR.DARKBLUE}>{content.header}</PageHeader>
          <HalfContentSubheader>{content.subheader}</HalfContentSubheader>
        </Container>
      </Fade>

      <InsightListings
        listings={convertMarkdownData(data.allWpArticles.edges)}
        limitPerPage={6}
        isDesktop={isDesktop}
      />

      <Container flexDirection="column" paddingVertical="0">
        <Fade>
          <ContentHeader>{content.webinarSection.title}</ContentHeader>
          <ContentParagraph>{content.webinarSection.description}</ContentParagraph>
        </Fade>
      </Container>

      {webinars}

      <PaddingLarge />
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpArticles {
      edges {
        node {
          id
          title
          excerpt
          articleFields {
            externalLink
            externalLogo {
              id
              sourceUrl
            }
            fieldGroupName
            isExternal
          }
          featuredImage {
            node {
              id
              sourceUrl
            }
          }
          content
          categories {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default InsightsPage;
