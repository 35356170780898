import { IVideoContentProps } from '../../components/VideoContent';

interface IHomePageContent {
  title: string;
  header: string;
  subheader: string;
  webinarSection: IWebinarSection;
}

interface IWebinarSection {
  title: string;
  description: string;
  webinars: IVideoContentProps[];
}

const webinarSection: IWebinarSection = {
  title: 'ThingCo Webinars',
  description:
    "Watch ThingCo's CII Webinars to gain further insight into how to extract the maximum value out of telematics data.",
  webinars: [
    {
      title: 'Developing a Smart FNOL Approach using Telematics',
      subheader: 'September 2021',
      linkText: 'Watch the CII Webinar',
      linkUrl: 'https://www.youtube.com/watch?v=RJjnvnZYeC4',
      videoUrl: 'https://www.youtube.com/embed/RJjnvnZYeC4',
    },
    {
      title: 'Achieving the Best Value from Telematics Data through Scoring & FNOL',
      subheader: 'June 2021',
      linkText: 'Watch the CII Webinar',
      linkUrl: 'https://www.youtube.com/watch?v=MsCBL7hr9y0',
      videoUrl: 'https://www.youtube.com/embed/MsCBL7hr9y0',
    },
  ],
};

const content: IHomePageContent = {
  title: 'ThingCo News & Insights',
  header: 'News & Insights',
  subheader: 'Get insights into ThingCo from our press releases and featured news articles',
  webinarSection: webinarSection,
};

export default content;
