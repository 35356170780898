import React from 'react';
import { VideoContentContainer, VideoContainer, ContentContainer, Link } from './styles';
import { ContentHeader, ContentSubheader, ContentParagraph, PaddingExtraSmall } from '../../styles';
import { Container } from '..';
import Video from '../Video';
import Slide from 'react-reveal/Slide';

export interface IVideoContentProps {
  reversed?: boolean;
  description?: string;
  title: string;
  subheader: string;
  linkText: string;
  linkUrl: string;
  videoUrl: string;
}

const VideoContent: React.FC<IVideoContentProps> = props => {
  return (
    <Container>
      <Slide left={!props.reversed} right={props.reversed}>
        <VideoContentContainer reversed={props.reversed}>
          <VideoContainer>
            <Video url={props.videoUrl} />
          </VideoContainer>
          <ContentContainer>
            <ContentHeader>{props.title}</ContentHeader>
            <ContentSubheader>{props.subheader}</ContentSubheader>
            {props.description ? <ContentParagraph>{props.description}</ContentParagraph> : null}
            <PaddingExtraSmall />
            <Link href={props.linkUrl}>{props.linkText}</Link>
          </ContentContainer>
        </VideoContentContainer>
      </Slide>
    </Container>
  );
};

VideoContent.defaultProps = {
  reversed: false,
};

export default VideoContent;
