import React from 'react';
import { VideoContainer, VideoFrame } from './styles';

export interface VideoProps {
  url: string;
  title?: string;
}

const Video = (props: VideoProps) => {
  return (
    <VideoContainer>
      <VideoFrame
        src={props.url}
        title={props.title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      />
    </VideoContainer>
  );
};

export default Video;
